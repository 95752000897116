import { FC } from 'react';
import IconProps from './IconProps';

const ThirdPartiesIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15.0727" cy="4.39394" r="3.53346" stroke="url(#paint0_linear_4183_66105)" strokeWidth="1.72096" />
        <circle cx="4.96645" cy="16.2575" r="3.53346" stroke="url(#paint1_linear_4183_66105)" strokeWidth="1.72096" />
        <circle cx="25.1787" cy="16.2575" r="3.53346" stroke="url(#paint2_linear_4183_66105)" strokeWidth="1.72096" />
        <path
          d="M19.7555 15.2323L17.8759 13.3336C17.6757 13.1313 17.3511 13.1313 17.1509 13.3336C16.9507 13.5358 16.9507 13.8637 17.1509 14.0659L18.1554 15.0806H14.2668C13.9837 15.0806 13.7542 15.3125 13.7542 15.5985C13.7542 15.8845 13.9837 16.1163 14.2668 16.1163H18.1554L17.151 17.1312C16.9508 17.3334 16.9508 17.6613 17.151 17.8635C17.3512 18.0658 17.6757 18.0658 17.8759 17.8635L19.7556 15.9648C19.9557 15.7625 19.9557 15.4346 19.7555 15.2323Z"
          fill="url(#paint3_linear_4183_66105)"
          stroke="url(#paint4_linear_4183_66105)"
          strokeWidth="0.573653"
        />
        <path
          d="M14.4865 9.81676L12.5877 11.6964C12.3855 11.8966 12.3855 12.2212 12.5877 12.4214C12.7899 12.6216 13.1178 12.6216 13.3201 12.4214L14.3348 11.4168L14.3348 15.3055C14.3348 15.5886 14.5666 15.8181 14.8526 15.8181C15.1386 15.8181 15.3705 15.5886 15.3705 15.3055L15.3705 11.4168L16.3853 12.4213C16.5876 12.6215 16.9155 12.6215 17.1177 12.4213C17.3199 12.2211 17.3199 11.8965 17.1177 11.6963L15.2189 9.81669C15.0166 9.61656 14.6887 9.61656 14.4865 9.81676Z"
          fill="url(#paint5_linear_4183_66105)"
          stroke="url(#paint6_linear_4183_66105)"
          strokeWidth="0.573653"
        />
        <path
          d="M9.94994 15.2323L11.8296 13.3336C12.0298 13.1313 12.3544 13.1313 12.5546 13.3336C12.7548 13.5358 12.7548 13.8637 12.5546 14.0659L11.55 15.0806H15.4387C15.7218 15.0806 15.9513 15.3125 15.9513 15.5985C15.9513 15.8845 15.7218 16.1163 15.4387 16.1163H11.55L12.5545 17.1312C12.7547 17.3334 12.7547 17.6613 12.5545 17.8635C12.3543 18.0658 12.0297 18.0658 11.8295 17.8635L9.94987 15.9648C9.74974 15.7625 9.74974 15.4346 9.94994 15.2323Z"
          fill="url(#paint7_linear_4183_66105)"
          stroke="url(#paint8_linear_4183_66105)"
          strokeWidth="0.573653"
        />
        <path
          d="M15.5389 6.12485V4.8601H16.8037C16.9273 4.8601 17.0459 4.81099 17.1333 4.72358C17.2207 4.63616 17.2698 4.51761 17.2698 4.39399C17.2698 4.27036 17.2207 4.15181 17.1333 4.06439C17.0459 3.97698 16.9273 3.92787 16.8037 3.92787H15.5389V2.66313C15.5389 2.53951 15.4898 2.42096 15.4024 2.33354C15.315 2.24613 15.1964 2.19702 15.0728 2.19702C14.9492 2.19702 14.8306 2.24613 14.7432 2.33354C14.6558 2.42096 14.6067 2.53951 14.6067 2.66313V3.92788H13.342C13.2183 3.92788 13.0998 3.97699 13.0124 4.0644C12.925 4.15182 12.8759 4.27038 12.8759 4.394C12.8759 4.51762 12.925 4.63618 13.0124 4.72359C13.0998 4.811 13.2183 4.86011 13.342 4.86011H14.6067V6.12485C14.6067 6.24847 14.6558 6.36703 14.7432 6.45444C14.8307 6.54185 14.9492 6.59096 15.0728 6.59096C15.1965 6.59096 15.315 6.54185 15.4024 6.45444C15.4898 6.36703 15.5389 6.24847 15.5389 6.12485H15.5389Z"
          fill="url(#paint9_linear_4183_66105)"
          stroke="url(#paint10_linear_4183_66105)"
          strokeWidth="0.286827"
        />
        <defs>
          <linearGradient id="paint0_linear_4183_66105" x1="10.6787" y1="2.68332" x2="19.4666" y2="6.43997" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_4183_66105" x1="0.57251" y1="14.5468" x2="9.36039" y2="18.3035" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint2_linear_4183_66105" x1="20.7848" y1="14.5468" x2="29.5727" y2="18.3035" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint3_linear_4183_66105" x1="13.7542" y1="14.6577" x2="19.368" y2="17.712" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint4_linear_4183_66105" x1="13.7542" y1="14.6577" x2="19.368" y2="17.712" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint5_linear_4183_66105" x1="13.9119" y1="15.8181" x2="16.9662" y2="10.2042" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint6_linear_4183_66105" x1="13.9119" y1="15.8181" x2="16.9662" y2="10.2042" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint7_linear_4183_66105" x1="15.9513" y1="14.6577" x2="10.3374" y2="17.712" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint8_linear_4183_66105" x1="15.9513" y1="14.6577" x2="10.3374" y2="17.712" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint9_linear_4183_66105" x1="12.8759" y1="3.53868" x2="17.2698" y2="5.41701" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint10_linear_4183_66105" x1="12.8759" y1="3.53868" x2="17.2698" y2="5.41701" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg {...attr} width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14.5004" cy="4.39394" r="3.53346" stroke="currentColor" strokeWidth="1.72096" />
      <circle cx="4.39394" cy="16.2576" r="3.53346" stroke="currentColor" strokeWidth="1.72096" />
      <circle cx="24.6059" cy="16.2576" r="3.53346" stroke="currentColor" strokeWidth="1.72096" />
      <path
        d="M19.183 15.2323L17.3034 13.3335C17.1032 13.1313 16.7786 13.1313 16.5784 13.3335C16.3782 13.5357 16.3782 13.8636 16.5784 14.0659L17.5829 15.0806H13.6943C13.4112 15.0806 13.1816 15.3124 13.1816 15.5984C13.1816 15.8844 13.4112 16.1163 13.6943 16.1163H17.5829L16.5784 17.1311C16.3782 17.3333 16.3782 17.6612 16.5784 17.8635C16.7786 18.0657 17.1032 18.0657 17.3034 17.8635L19.1831 15.9647C19.3832 15.7624 19.3832 15.4345 19.183 15.2323Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.573653"
      />
      <path
        d="M13.9137 9.81682L12.015 11.6965C11.8127 11.8966 11.8127 12.2212 12.015 12.4214C12.2172 12.6216 12.5451 12.6216 12.7473 12.4214L13.762 11.4169L13.762 15.3055C13.762 15.5887 13.9939 15.8182 14.2799 15.8182C14.5659 15.8182 14.7977 15.5887 14.7977 15.3055L14.7977 11.4169L15.8126 12.4214C16.0148 12.6216 16.3427 12.6216 16.5449 12.4214C16.7472 12.2212 16.7472 11.8966 16.5449 11.6964L14.6462 9.81675C14.4439 9.61662 14.116 9.61662 13.9137 9.81682Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.573653"
      />
      <path
        d="M9.37755 15.2323L11.2572 13.3335C11.4574 13.1313 11.782 13.1313 11.9822 13.3335C12.1824 13.5357 12.1824 13.8636 11.9822 14.0659L10.9776 15.0806H14.8663C15.1494 15.0806 15.3789 15.3124 15.3789 15.5984C15.3789 15.8844 15.1494 16.1163 14.8663 16.1163H10.9776L11.9821 17.1311C12.1823 17.3333 12.1823 17.6612 11.9821 17.8635C11.7819 18.0657 11.4573 18.0657 11.2571 17.8635L9.37748 15.9647C9.17735 15.7624 9.17735 15.4345 9.37755 15.2323Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.573653"
      />
      <path
        d="M14.9668 6.12482V4.86007H16.2315C16.3552 4.86007 16.4737 4.81096 16.5611 4.72355C16.6485 4.63613 16.6977 4.51758 16.6977 4.39396C16.6977 4.27033 16.6485 4.15178 16.5611 4.06436C16.4737 3.97695 16.3552 3.92784 16.2315 3.92784H14.9668V2.6631C14.9668 2.53948 14.9177 2.42093 14.8303 2.33351C14.7429 2.2461 14.6243 2.19699 14.5007 2.19699C14.3771 2.19699 14.2585 2.2461 14.1711 2.33351C14.0837 2.42093 14.0346 2.53948 14.0346 2.6631V3.92785H12.7698C12.6462 3.92785 12.5276 3.97696 12.4402 4.06437C12.3528 4.15179 12.3037 4.27034 12.3037 4.39397C12.3037 4.51759 12.3528 4.63614 12.4402 4.72356C12.5276 4.81097 12.6462 4.86008 12.7698 4.86008H14.0346V6.12482C14.0346 6.24844 14.0837 6.367 14.1711 6.45441C14.2585 6.54182 14.3771 6.59093 14.5007 6.59093C14.6243 6.59093 14.7429 6.54182 14.8303 6.45441C14.9177 6.367 14.9668 6.24844 14.9668 6.12482H14.9668Z"
        fill={accentColor}
        stroke={accentColor}
        strokeWidth="0.286827"
      />
    </svg>
  );
};

export default ThirdPartiesIcon;
